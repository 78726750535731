@import "./mixins.scss";

.lawnArea {
  display: flex;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 1.125rem;
  border-radius: 1.875rem;
  backdrop-filter: blur(2px);
  width: fit-content;
  margin-bottom: 2.5rem;
  background-color: #65ae6b;
  color: #fff;
  font-weight: 700;

  @include lg {
    font-size: 13.25px;
    margin-bottom: 1.5rem;
  }

  @include md {
    display: inline-flex;
    padding: 0 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 18px;
    border-radius: 30px;
    background: #65ae6b;
    position: absolute;
    top: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.6px;
    letter-spacing: -0.24px;
    white-space: nowrap;
  }
}

.areaValue {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}
