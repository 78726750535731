@import "./mixins.scss";

.container {
  border-radius: 24px;
  background: #f6f6f6;
  box-shadow: 0px 3.89911px 12.67211px 0px rgba(36, 78, 46, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  width: calc(100% - 32px);
  overflow: hidden;
  margin-top: 40px;

  .subText {
    display: flex;
    height: 1.75rem;
    padding: 0rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border-radius: 2.6875rem;
    border: 0.125rem solid #65ae6b;
    width: fit-content;

    color: #65ae6b;
    text-align: center;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.0562rem;
    text-transform: uppercase;

    margin-top: 3.125rem;
    margin-bottom: 1.5rem;

    @include md {
      font-size: 10px;
      line-height: 13px;
      margin-top: 22px;
      margin-bottom: 16px;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-height: none;
    overflow: hidden;
    object-fit: cover;

    @include md {
      height: auto;
    }
  }

  h1 {
    color: #1e1e1e;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.8px;
    max-width: 600px;

    @include md {
      font-size: 24px;
      line-height: 26.4px;
      letter-spacing: -0.48px;
      width: 90%;
      margin-top: 0;
    }
  }

  p {
    color: #000;
    text-align: center;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.5px;
    letter-spacing: -0.38px;
    max-width: 745px;
    margin-top: 0;
    margin-bottom: 2.5rem;
    width: 90%;

    @include md {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.24px;
      margin-bottom: 27px;
    }
  }

  button {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline-flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #65ae6b;
    border: none;
    margin-bottom: 50px;
    width: 90%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    @include md {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 24px;
    }
  }
}
